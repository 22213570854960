
                                    $static-content-cache-base-url: "https://s3-ap-southeast-2.amazonaws.com/prod-automait-public-website-content";
                                    $cloud-front-base-url: "https://dr1k2g3wmnols.cloudfront.net";
                                
/*Load GMSV fonts*/
@font-face {
  font-family: 'Louis';
  src: url('GMSV-Louis-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('GMSV-Roboto-Regular.ttf') format('truetype');
}

