
                                    $static-content-cache-base-url: "https://s3-ap-southeast-2.amazonaws.com/prod-automait-public-website-content";
                                    $cloud-front-base-url: "https://dr1k2g3wmnols.cloudfront.net";
                                
//===================================================
@import '../../core';

//Theme Colours
$color-theme: #cd9834;
$color-theme--darker: #262626;
$color-button: $color-theme;
$color-button--hover: #808080;
$color-button-text--hover: $color-theme--darker;
$color-button-text: $color-white;

//===================================================

// Text links
$link-color: $color-theme;

//Main nav themes
$nav-color: $color-theme;
$nav-mobile-color: $color-theme;
$nav-title-color: $color-black-500;
$nav-bg-color: $color-white;
$nav-br-color: $color-white;
$nav-active-br-color: $color-black-50;
$nav-toggle-bg-color: $color-white;
$nav-hover-color: $color-white;
$nav-hover-bg-color: $color-theme;
$nav-logo: $static-content-cache-base-url + "/images/logos/brands/gmsv-logo.svg";

//Contact Nav themes
$contact-nav-bg-color: $color-theme;
$contact-nav-color: $color-white;
$contact-nav-br-color: $color-theme;

//Sub Nav themes
$sub-nav-bg-color: $color-white;
$sub-nav-br-color: $color-black-50;
$sub-nav-hover-color: $color-theme;
$sub-nav-hover-bg-color: $color-black-50;
$sub-nav-title-color: $color-black-500;
$sub-nav-desc-color: $color-black-500;
$sub-nav-mobile-active-title-color: $color-theme;

//Colour for headers across the site (.header--theme-color)
$header-color: $color-black-500;

//Colours for cards
$card-header-color: $header-color;
$card-price-color: $header-color;
$card-icon-color: $header-color;

//Colours for the Main header on content pages
$header-title-color: $color-white;
$header-sub-title-color: $color-white;
$header-gradient-color-left: rgb(17, 17, 17);
$header-gradient-color-right: #2c2c2c;

//Colours for the VDP page header
$header-VDP-title-color: $color-black-500;
$header-VDP-gradient-color-left: #FBFBFB;
$header-VDP-gradient-color-right: #ECECEC;

//Colour for the sticky footer elements and VDP header
$sticky-footer-background-color: $color-black-800;
$form-header-color: $color-black-800;

// Panels
$panel-background-color: $color-theme;

//Fonts
// Font Families (ff)
$ff-Louis: Louis, Tahoma, sans-serif;
$ff-Roboto: Roboto, Helvetica, sans-serif;

$ff-heading: 700, $ff-Louis;
$ff-main: normal, $ff-Roboto;
$ff-label: normal, $ff-Roboto;
$ff-main-bold: 700, $ff-Roboto;

.nav__item {
  font-weight: 700 !important;
}

@import "../../../src/application";